import { guard } from "@/functions";

export default [
    {
        name: "carousel",
        path: "/carousel",
        component: () => import("@/modules/Carousel"),
        beforeEnter: guard,
    },
];
