import { guard } from "@/functions";

export default [
    {
        name: "lastVideoId",
        path: "/youtube/lastVideoId",
        component: () => import("@/modules/YouTube/LastVideoId"),
        beforeEnter: guard,
    },
];
