import { guard } from "@/functions";

export default [
    {
        name: "bot",
        path: "/bot",
        component: () => import("@/modules/Bot"),
        beforeEnter: guard,
    },
    {
        name: "rip",
        path: "/bot/rip",
        component: () => import("@/modules/Bot/Rip"),
        beforeEnter: guard,
    },
    {
        name: "say",
        path: "/bot/say",
        component: () => import("@/modules/Bot/Say"),
        beforeEnter: guard,
    },
    {
        name: "subhype",
        path: "/bot/subhype",
        component: () => import("@/modules/Bot/Subhype"),
        beforeEnter: guard,
    },
];
