import { guard } from "@/functions";

export default [
    {
        name: "settings",
        path: "/settings",
        component: () => import("@/views/Settings"),
        beforeEnter: guard,
    },
];
