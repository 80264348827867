import { guard } from "@/functions";

export default [
    {
        name: "reward",
        path: "/reward",
        component: () => import("@/modules/Twitch/Reward"),
        beforeEnter: guard,
    },
];
