import { guard } from "@/functions";

export default [
    {
        name: "poll",
        path: "/poll",
        component: () => import("@/modules/Poll"),
        beforeEnter: guard,
    },
];
