import axios from "@/axios";

const getters = {
    services(state) {
        return state.services;
    },
    currencies(state) {
        return state.currencies;
    },
};

const state = {
    services: null,
    currencies: null,
};

export const SET_SERVICES = "setServices";
export const SET_CURRENCIES = "setCurrencies";

const mutations = {
    [SET_SERVICES](state, payload) {
        state.services = payload;
    },
    [SET_CURRENCIES](state, payload) {
        state.currencies = payload;
    },
};

export const GET_SERVICES = "getServices";
export const GET_CURRENCIES = "getCurrencies";

const actions = {
    [GET_SERVICES](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/settings.services")
                .then((response) => {
                    context.commit(SET_SERVICES, response.data);
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_CURRENCIES](context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/currencies")
                .then((response) => {
                    context.commit(SET_CURRENCIES, response.data.response);
                    resolve(response.data.response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
