import { guard } from "@/functions";

export default [
    {
        name: "auction",
        path: "/auction",
        component: () => import("@/modules/Auction"),
        beforeEnter: guard,
    },
];
