<template>
    <router-view />
</template>

<script>
// import { SET_ACTIONS, SET_ACTIONS_LIST } from "@/store/modules/timer";
//
// export default {
//     mounted() {
//         this.$store.commit(SET_ACTIONS);
//         this.$store.commit(SET_ACTIONS_LIST);
//     },
// };
</script>
