<template>
    <div class="page">
        <!--        <AsideLayout />-->
        <NavbarLayout />
        <div class="page-wrapper">
            <ContainerLayout>
                <template #header>
                    <slot name="header"></slot>
                </template>
                <slot></slot>
            </ContainerLayout>
            <FooterLayout />
        </div>
    </div>
</template>

<script>
// import AsideLayout from "@/layouts/Base/Aside";
import FooterLayout from "@/layouts/Base/Footer";
import ContainerLayout from "@/layouts/Base/Container";
import NavbarLayout from "@/layouts/Base/Navbar";
export default {
    name: "BaseLayout",
    components: { NavbarLayout, ContainerLayout, FooterLayout },
};
</script>

<style scoped></style>
