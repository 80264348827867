import { guard } from "@/functions";

export default [
    {
        name: "chat",
        path: "/chat",
        component: () => import("@/modules/Chat"),
        beforeEnter: guard,
    },
];
