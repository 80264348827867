<template>
    <!-- Page header -->
    <div class="page-header d-print-none">
        <div class="container-xl">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderLayout",
};
</script>

<style scoped></style>
