<template>
    <!-- Navbar -->
    <header class="navbar navbar-expand-md navbar-light d-print-none">
        <div class="container-xl">
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-menu"
                aria-controls="navbar-menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <h1
                class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"
            >
                <a href="/">
                    <img
                        :src="require('@/assets/logo.png')"
                        width="110"
                        height="32"
                        alt="StreamerFun"
                        class="navbar-brand-image"
                    />
                </a>
            </h1>
            <div class="navbar-nav flex-row order-md-last">
                <div class="d-none d-md-flex me-2">
                    <a
                        href="javascript:void(0)"
                        class="nav-link px-0 hide-theme-dark"
                        title="Switch color mode"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        @click="toggleTheme()"
                    >
                        <!-- Download SVG icon from http://tabler-icons.io/i/moon -->
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                            />
                        </svg>
                    </a>
                    <a
                        href="javascript:void(0)"
                        class="nav-link px-0 hide-theme-light"
                        title="Enable light mode"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        @click="toggleTheme()"
                    >
                        <!-- Download SVG icon from http://tabler-icons.io/i/sun -->
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="4" />
                            <path
                                d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"
                            />
                        </svg>
                    </a>
                    <!--                    <div
                        class="nav-item dropdown d-none d-md-flex me-3"
                        v-if="user"
                    >
                        <a
                            href="#"
                            class="nav-link px-0"
                            data-bs-toggle="dropdown"
                            tabindex="-1"
                            aria-label="Show notifications"
                        >
                            &lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/bell &ndash;&gt;
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path
                                    d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"
                                />
                                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                            </svg>
                            <span class="badge bg-red"></span>
                        </a>
                        <div
                            class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card"
                        >
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Last updates</h3>
                                </div>
                                <div
                                    class="list-group list-group-flush list-group-hoverable"
                                >
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span
                                                    class="status-dot status-dot-animated bg-red d-block"
                                                ></span>
                                            </div>
                                            <div class="col text-truncate">
                                                <a
                                                    href="#"
                                                    class="text-body d-block"
                                                    >Example 1</a
                                                >
                                                <div
                                                    class="d-block text-muted text-truncate mt-n1"
                                                >
                                                    Change deprecated html tags
                                                    to text decoration classes
                                                    (#29604)
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    href="#"
                                                    class="list-group-item-actions"
                                                >
                                                    &lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/star &ndash;&gt;
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon text-muted"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <path
                                                            d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span
                                                    class="status-dot d-block"
                                                ></span>
                                            </div>
                                            <div class="col text-truncate">
                                                <a
                                                    href="#"
                                                    class="text-body d-block"
                                                    >Example 2</a
                                                >
                                                <div
                                                    class="d-block text-muted text-truncate mt-n1"
                                                >
                                                    justify-content:between ⇒
                                                    justify-content:space-between
                                                    (#29734)
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    href="#"
                                                    class="list-group-item-actions show"
                                                >
                                                    &lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/star &ndash;&gt;
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon text-yellow"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <path
                                                            d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span
                                                    class="status-dot d-block"
                                                ></span>
                                            </div>
                                            <div class="col text-truncate">
                                                <a
                                                    href="#"
                                                    class="text-body d-block"
                                                    >Example 3</a
                                                >
                                                <div
                                                    class="d-block text-muted text-truncate mt-n1"
                                                >
                                                    Update change-version.js
                                                    (#29736)
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    href="#"
                                                    class="list-group-item-actions"
                                                >
                                                    &lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/star &ndash;&gt;
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon text-muted"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <path
                                                            d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <span
                                                    class="status-dot status-dot-animated bg-green d-block"
                                                ></span>
                                            </div>
                                            <div class="col text-truncate">
                                                <a
                                                    href="#"
                                                    class="text-body d-block"
                                                    >Example 4</a
                                                >
                                                <div
                                                    class="d-block text-muted text-truncate mt-n1"
                                                >
                                                    Regenerate package-lock.json
                                                    (#29730)
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    href="#"
                                                    class="list-group-item-actions"
                                                >
                                                    &lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/star &ndash;&gt;
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon text-muted"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <path
                                                            d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
                <div class="nav-item dropdown" v-if="user">
                    <a
                        href="#"
                        class="nav-link d-flex lh-1 text-reset p-0"
                        data-bs-toggle="dropdown"
                        aria-label="Open user menu"
                    >
                        <span
                            class="avatar avatar-sm"
                            style="
                                background-image: url(https://sun9-43.userapi.com/c845020/v845020422/430ff/uzjLU0cyA3A.jpg);
                            "
                        ></span>
                        <div class="d-none d-xl-block ps-2">
                            <div>{{ user.name }}</div>
                            <div
                                class="mt-1 small text-success fw-bold"
                                v-if="user.plus"
                            >
                                <i>StreamerPlus</i>
                            </div>
                            <div class="mt-1 small text-muted" v-else>
                                Базовый доступ
                            </div>
                        </div>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                    >
                        <!--                        <a href="#" class="dropdown-item">Статус</a>-->
                        <!--                        <a href="#" class="dropdown-item">Профиль</a>-->
                        <a
                            target="_blank"
                            href="https://t.me/StreamerSupportBot"
                            class="dropdown-item"
                            >Поддержка</a
                        >
                        <div class="dropdown-divider"></div>
                        <router-link
                            :to="{ name: 'settings' }"
                            class="dropdown-item"
                            >Настройки</router-link
                        >
                        <a :href="$link.auth + '/logout'" class="dropdown-item"
                            >Выход</a
                        >
                    </div>
                </div>
                <div class="nav-item" v-if="!user">
                    <a
                        :href="$link.auth + '/'"
                        @click="$metrika.reachGoal('join')"
                        class="btn btn-primary"
                        >Войти</a
                    >
                </div>
                <div class="nav-item ms-2" v-if="!user">
                    <a
                        :href="$link.auth + '/register'"
                        @click="$metrika.reachGoal('register')"
                        class="btn btn-success"
                        >Создать аккаунт</a
                    >
                </div>
            </div>
            <div class="collapse navbar-collapse" id="navbar-menu">
                <div
                    class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center"
                >
                    <ul class="navbar-nav">
                        <MenuItem
                            :item="item"
                            :key="'menu_' + index"
                            v-for="(item, index) in menu"
                        />
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import MenuItem from "@/layouts/Base/Navbar/MenuItem";
import { GET_USER } from "@/store/modules/user";
export default {
    name: "NavbarLayout",
    components: { MenuItem },
    data: () => {
        return {
            menu: [],
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
    },
    methods: {
        toggleTheme() {
            document.body.classList.toggle("theme-dark");
        },
        generateMenu() {
            this.menu = [
                {
                    name: "Главная",
                    icon: "fa-solid fa-house",
                    to: { name: "index" },
                },
                {
                    name: "Виджеты",
                    icon: "fa-solid fa-bars",
                    children: [
                        {
                            name: "Twitch",
                            children: [
                                {
                                    name: "Ставки",
                                    to: { name: "prediction" },
                                },
                                // {
                                //     name: "Награды",
                                //     to: { name: "reward" },
                                // },
                            ],
                        },
                        {
                            name: "YouTube",
                            children: [
                                {
                                    name: "ID последнего видео",
                                    to: { name: "lastVideoId" },
                                },
                            ],
                        },
                        {
                            name: "Колесо",
                            to: { name: "wheel" },
                        },
                        {
                            name: "BattleRoyal",
                            to: { name: "royal" },
                        },
                        // {
                        //     name: "Аукцион",
                        //     to: { name: "auction" },
                        // },
                        {
                            name: "Таймер",
                            to: { name: "timer" },
                        },
                        // {
                        //     name: "Карусель",
                        //     to: { name: "carousel" },
                        // },
                        // {
                        //     name: "Розыгрыш в чате",
                        //     to: { name: "chatdraw" },
                        // },
                        // {
                        //     name: "Сундук",
                        //     to: { name: "chest" },
                        // },
                        // {
                        //     name: "Дуэль",
                        //     to: { name: "duel" },
                        // },
                        // {
                        //     name: "Лобби",
                        //     to: { name: "lobby" },
                        // },
                        // {
                        //     name: "Опрос",
                        //     to: { name: "poll" },
                        // },
                        // {
                        //     name: "Звуковые эффекты",
                        //     to: { name: "sound" },
                        // },
                        // {
                        //     name: "Сокровищница",
                        //     to: { name: "treasure" },
                        // },
                    ],
                },
                // {
                //     name: "Бот",
                //     icon: "",
                //     children: [
                //         {
                //             name: "Настройка",
                //             to: { name: "bot" },
                //         },
                //         {
                //             name: "Счетчик смертей",
                //             to: { name: "rip" },
                //         },
                //         {
                //             name: "Сабхайп",
                //             to: { name: "subhype" },
                //         },
                //         {
                //             name: "Say",
                //             to: { name: "say" },
                //         },
                //     ],
                // },
                {
                    name: "Донаты",
                    hide: !(
                        this.user &&
                        this.user.connections.find(
                            (v) => [5, 6, 7].indexOf(v.service_id) !== -1
                        )
                    ),
                    icon: "fa-solid fa-dollar-sign",
                    to: { name: "donate" },
                },
                // {
                //     name: "Статистика",
                //     children: [
                //         {
                //             name: "Донаты",
                //             to: { name: "index" },
                //         },
                //         {
                //             name: "Топ",
                //             children: [
                //                 {
                //                     name: "Чаттеры",
                //                     to: { name: "index" },
                //                 },
                //                 {
                //                     name: "Донатеры",
                //                     to: { name: "index" },
                //                 },
                //                 {
                //                     name: "Награды",
                //                     to: { name: "index" },
                //                 },
                //                 {
                //                     name: "Баллы",
                //                     to: { name: "index" },
                //                 },
                //             ],
                //         },
                //     ],
                // },
                {
                    name: "Гайды",
                    icon: "fa-solid fa-book",
                    children: [
                        {
                            name: "Как начать пользоваться",
                            to: { name: "howToStart" },
                        },
                        {
                            name: "Twitch",
                            children: [
                                {
                                    name: "Как установить виджет ставок",
                                    to: {
                                        name: "howToInstallWidgetPrediction",
                                    },
                                },
                                // {
                                //     name: "Как подключить бота",
                                //     to: { name: "index" },
                                // },
                            ],
                        },
                        {
                            name: "Виджеты",
                            children: [
                                {
                                    name: "Гайд по Колесу",
                                    to: {
                                        name: "wheelInfo",
                                    },
                                },
                                {
                                    name: "Гайд по BattleRoyal",
                                    to: {
                                        name: "battleRoyalInfo",
                                    },
                                },
                                {
                                    name: "Гайд по Таймерам",
                                    to: {
                                        name: "timerInfo",
                                    },
                                },
                                {
                                    name: "Гайд по Наборам данных",
                                    to: {
                                        name: "datasetInfo",
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "<b class='text-danger'>StreamerPlus</b>",
                    icon: "fa-solid fa-rocket-launch text-danger",
                    to: { name: "plus" },
                },
            ];
        },
    },
    beforeCreate() {
        this.$store.dispatch(GET_USER);
    },
    mounted() {
        this.generateMenu();
    },
};
</script>

<style scoped></style>
