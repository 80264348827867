// import api from "@/api";

import axios from "@/axios";

const getters = {
    wheelStyles(state) {
        return state.styles;
    },
};

const state = {
    wheels: [],
    styles: [
        // {
        //     id: 1,
        //     name: "style 1",
        // },
        // {
        //     id: 2,
        //     name: "style 2",
        // },
        // {
        //     id: 3,
        //     name: "style 3",
        // },
    ],
};

export const SET_WHEEL_STYLES = "setWheelStyles";
export const ADD_WHEEL_STYLE = "addWheelStyle";
export const MODIFY_WHEEL_STYLE = "modifyWheelStyle";
const mutations = {
    [SET_WHEEL_STYLES](state, payload) {
        state.styles = payload;
    },
    [ADD_WHEEL_STYLE](state, payload) {
        state.styles.push(payload);
    },
    [MODIFY_WHEEL_STYLE](state, payload) {
        state.styles = state.styles.map((s) => {
            if (s.id === payload.id) s = payload;
            return s;
        });
    },
};

export const SPIN_WHEEL = "spinWheel";
export const CREATE_WHEEL_STYLE = "createWheelStyle";
export const GET_WHEEL_STYLES = "getWheelStyles";
export const UPDATE_WHEEL_STYLE = "updateWheelStyle";
const actions = {
    [SPIN_WHEEL](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/wheel.spin", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_WHEEL_STYLES](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/wheel.styles")
                .then((response) => {
                    context.commit(SET_WHEEL_STYLES, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [CREATE_WHEEL_STYLE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/wheel.styles.create", payload)
                .then((response) => {
                    context.commit(ADD_WHEEL_STYLE, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_WHEEL_STYLE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/wheel.styles.update", payload)
                .then((response) => {
                    context.commit(MODIFY_WHEEL_STYLE, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
