// import api from "@/api";

import axios from "@/axios";

const getters = {
    wheelSounds(state) {
        return state.wheelSounds;
    },
};

const state = {
    wheelSounds: [],
};

export const SET_WHEEL_SOUNDS = "setWheelSounds";

const mutations = {
    [SET_WHEEL_SOUNDS](state, payload) {
        state.wheelSounds = payload;
    },
};

export const GET_WHEEL_SOUNDS = "getWheelSounds";
const actions = {
    [GET_WHEEL_SOUNDS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/wheel.sounds")
                .then((response) => {
                    context.commit(SET_WHEEL_SOUNDS, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
