import { guard } from "@/functions";

export default [
    {
        name: "treasure",
        path: "/treasure",
        component: () => import("@/modules/Treasure"),
        beforeEnter: guard,
    },
];
