<template>
    <div class="ribbon bg-indigo" v-if="card.is_new">Новинка</div>
    <div class="ribbon bg-instagram s-plus" v-if="card.is_plus">plus</div>
    <div
        class="card-img-top img-responsive img-responsive-16x9"
        :style="{
            'background-image': card.background
                ? 'url(' + card.background.image + ')'
                : null,
        }"
    ></div>
    <div class="card-body">
        <h3 class="card-title">
            <i v-if="locked" class="fa-regular fa-lock text-muted me-1"></i>
            {{ card.name }}
        </h3>
        <p class="text-muted">
            {{ card.description }}
        </p>
    </div>
</template>

<script>
export default {
    name: "WidgetCard",
    props: ["card", "locked"],
};
</script>

<style scoped lang="scss">
.s-plus {
    text-transform: lowercase;
    font-size: 12px;
    font-style: italic;
    text-shadow: 2px 0px black;
}

.ribbon {
    &:nth-child(2) {
        margin-top: 40px;
    }
    &:nth-child(3) {
        margin-top: 80px;
    }
}
</style>
