// import api from "@/api";

import axios from "@/axios";

const getters = {
    timers(state) {
        return state.timers;
    },
    timerSounds(state) {
        return state.sounds;
    },
    timerActions(state) {
        return state.actions;
    },
    timerActionList(state) {
        return state.actionList;
    },
};

const state = {
    timers: [
        // {
        //     id: 1,
        //     name: "test1",
        // },
        // {
        //     id: 2,
        //     name: "test2",
        // },
        // {
        //     id: 3,
        //     name: "test3",
        // },
    ],
    sounds: [],
    actionList: [],
    actions: [],
};

export const SET_TIMERS = "setTimers";
export const ADD_TIMER = "addTimer";
export const SET_TIMER_SOUNDS = "setTimerSounds";
export const SET_TIMER_ACTIONS = "setTimerActions";
export const SET_TIMER_ACTIONS_LIST = "setTimerActionList";

const mutations = {
    [SET_TIMERS](state, payload) {
        state.timers = payload;
    },
    [ADD_TIMER](state, payload) {
        state.timers.push(payload);
    },
    [SET_TIMER_SOUNDS](state, payload) {
        state.sounds = payload;
    },
    [SET_TIMER_ACTIONS](state) {
        state.actions = [
            {
                id: 1, // start
                name: "Действие при первом запуске таймера",
                text: "При первом запуске таймера",
                items: state.actionList.filter(
                    (v) => [0, 1].indexOf(v.id) !== -1
                ),
            },
            {
                id: 2, // update-seconds
                name: "Действие при обновлении таймера",
                text: "При обновлении таймера",
                items: state.actionList.filter(
                    (v) => [0, 1].indexOf(v.id) !== -1
                ),
            },
            {
                id: 3, // pause
                name: "Действие при паузе таймера",
                text: "При паузе таймера",
                items: state.actionList.filter(
                    (v) => [0, 1].indexOf(v.id) !== -1
                ),
            },
            {
                id: 4, // play
                name: "Действие после снятия с паузы",
                text: "После снятия с паузы",
                items: state.actionList.filter(
                    (v) => [0, 1].indexOf(v.id) !== -1
                ),
            },
            {
                id: 5, // reset
                name: "Действие при сбросе таймера",
                text: "При сбросе таймера",
                items: state.actionList.filter(
                    (v) => [0, 1].indexOf(v.id) !== -1
                ),
            },
            {
                id: 6, // restart
                name: "Действие при перезапуске таймера",
                text: "При перезапуске таймера",
                items: state.actionList.filter(
                    (v) => [0, 1].indexOf(v.id) !== -1
                ),
            },
            {
                id: 7, // finish
                name: "Действие по окончанию таймера",
                text: "По окончанию таймера",
                items: state.actionList.filter(
                    (v) => [0, 1, 2, 3].indexOf(v.id) !== -1
                ),
            },
        ];
    },
    [SET_TIMER_ACTIONS_LIST](state) {
        state.actionList = [
            {
                id: 0,
                name: "Ничего не делать",
            },
            {
                id: 1,
                name: "Воспроизвести звук",
                items: state.sounds,
            },
            {
                id: 2,
                name: "Запустить колесо",
            },
            {
                id: 3,
                name: "Запустить таймер",
                items: state.timers,
            },
        ];
    },
};

export const GET_TIMERS = "getTimers";
export const GET_TIMER_SOUNDS = "getTimerSounds";
export const CREATE_TIMER = "createTimer";
export const UPDATE_TIMER = "updateTimer";
export const DELETE_TIMER = "deleteTimer";
export const ADD_TIMER_ACTION = "addTimerAction";
export const DELETE_TIMER_ACTION = "deleteTimerAction";
export const UPDATE_TIMER_ACTION = "updateTimerAction";

const actions = {
    [GET_TIMERS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers")
                .then((response) => {
                    context.commit(SET_TIMERS, response.data);

                    context.commit(SET_TIMER_ACTIONS_LIST);
                    context.commit(SET_TIMER_ACTIONS);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_TIMER_SOUNDS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.sounds")
                .then((response) => {
                    context.commit(SET_TIMER_SOUNDS, response.data);

                    context.commit(SET_TIMER_ACTIONS_LIST);
                    context.commit(SET_TIMER_ACTIONS);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [CREATE_TIMER](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.create", payload)
                .then((response) => {
                    context.commit(ADD_TIMER, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_TIMER](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.update", payload)
                .then((response) => {
                    // context.commit(ADD_TIMER, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [DELETE_TIMER](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.delete", payload)
                .then((response) => {
                    // context.commit(ADD_TIMER, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [ADD_TIMER_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.action.add", payload)
                .then((response) => {
                    // context.commit(ADD_TIMER, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [DELETE_TIMER_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.action.delete", payload)
                .then((response) => {
                    // context.commit(ADD_TIMER, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_TIMER_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/timers.action.update", payload)
                .then((response) => {
                    // context.commit(ADD_TIMER, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
