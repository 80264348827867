import { guard } from "@/functions";

export default [
    {
        name: "duel",
        path: "/duel",
        component: () => import("@/modules/Duel"),
        beforeEnter: guard,
    },
];
