import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import keydown from "../directives/keydown";
import VueYandexMetrika from "vue3-yandex-metrika";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

require("@tabler/core/dist/js/tabler.min");
require("@tabler/core/dist/css/tabler.min.css");
// require("@tabler/core/dist/css/tabler-flags.min.css");
// require("@tabler/core/dist/css/tabler-payments.min.css");
require("@tabler/core/dist/css/tabler-vendors.min.css");

require("./styles/_packages.scss");
require("./styles/_colors.scss");
require("./styles/main.scss");

import ws from "@/websocket";

const head = createHead({
    titleTemplate: (title) => `${title} - StreamerFun`,
});

const app = createApp(App);
app.use(store).use(router).use(head);
app.directive("keydown", keydown);
app.config.globalProperties.$ws = ws;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$link = {
    auth:
        process.env.NODE_ENV === "production"
            ? "https://auth.streamer.fun"
            : "http://auth.2streamer.local",
    pay:
        process.env.NODE_ENV === "production"
            ? "https://pay.streamer.fun"
            : "http://pay.2streamer.local",
    app:
        process.env.NODE_ENV === "production"
            ? "https://streamer.fun"
            : "http://2streamer.local",
};

app.use(VueYandexMetrika, {
    id: 76148965,
    router: router,
    env: process.env.NODE_ENV,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    },
});
app.mount("#app");
