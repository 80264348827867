// import axios from "@/axios";
import { GET_USER } from "@/store/modules/user";
import store from "@/store";

export const declOfNum = function (number, words) {
    return words[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
    ];
};

export const guard = function (to, from, next) {
    store
        .dispatch(GET_USER)
        .then((response) => {
            // console.log(response);
            if (response) {
                next();
            } else {
                if (process.env.NODE_ENV === "production") {
                    window.location.href = "https://auth.streamer.fun";
                } else {
                    window.location.href = "http://auth.2streamer.local";
                }
            }
        })
        .catch(() => {
            if (process.env.NODE_ENV === "production") {
                window.location.href = "https://auth.streamer.fun";
            } else {
                window.location.href = "http://auth.2streamer.local";
            }
        });
};

export default { declOfNum, guard };
