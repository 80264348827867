import { guard } from "@/functions";

export default [
    {
        name: "donate",
        path: "/donate",
        component: () => import("@/modules/Donate"),
        beforeEnter: guard,
    },
    {
        name: "donatePopout",
        path: "/popout/donations",
        component: () => import("@/popout/DonationsPopout"),
        beforeEnter: guard,
    },
];
