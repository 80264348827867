import axios from "@/axios";

const getters = {
    user(state) {
        return state.user;
    },
};

const state = {
    user: window.user ? window.user : null,
};

export const SET_USER = "setUser";

const mutations = {
    [SET_USER](state, payload) {
        state.user = payload;
    },
};

export const GET_USER = "getUser";
export const SET_SETTINGS = "setSettings";
export const ACTIVATE_CODE = "activateCode";

const actions = {
    [GET_USER](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/user")
                .then((response) => {
                    if (
                        response.data &&
                        Object.keys(response.data).length > 0
                    ) {
                        context.commit(SET_USER, response.data);
                        resolve(response.data);
                    } else {
                        context.commit(SET_USER, false);
                        resolve(false);
                    }
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [SET_SETTINGS](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/settings.set", payload)
                .then((response) => {
                    context.commit(SET_USER, response.data);
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [ACTIVATE_CODE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/settings.code", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response.data);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
