<template>
    <li
        class="nav-item hover"
        :class="{ dropdown: item.children }"
        v-if="!item.hide"
    >
        <router-link
            class="nav-link"
            role="button"
            v-if="item.to"
            :to="item.to"
        >
            <span class="nav-link-icon d-md-none d-lg-inline-block text-center">
                <i :class="item.icon"></i>
            </span>
            <span class="nav-link-title" v-html="item.name"></span>
        </router-link>
        <a
            ref="el"
            v-if="item.children"
            :class="{ 'dropdown-toggle': item.children }"
            class="nav-link"
            href="javascript:void(0)"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            role="button"
            aria-expanded="false"
        >
            <span class="nav-link-icon d-md-none d-lg-inline-block text-center">
                <i :class="item.icon"></i>
            </span>
            <span class="nav-link-title" v-html="item.name"></span>
        </a>
        <div class="dropdown-menu" v-if="item.children">
            <div class="dropdown-menu-columns">
                <div
                    class="dropdown-menu-column"
                    v-for="(chunk, index) in chunks(item.children)"
                    :key="'chunk_' + index"
                >
                    <div v-for="child in chunk" :key="'menu_child_' + child.id">
                        <router-link
                            class="dropdown-item"
                            v-if="!child.children"
                            :to="child.to"
                            v-html="child.name"
                        >
                        </router-link>
                        <div class="dropend" v-if="child.children">
                            <a
                                class="dropdown-item dropdown-toggle"
                                href="javascript:void(0)"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                role="button"
                                aria-expanded="false"
                                v-html="child.name"
                            ></a>
                            <div class="dropdown-menu">
                                <router-link
                                    v-for="el in child.children"
                                    :key="'menu_child_el_' + el.id"
                                    class="dropdown-item"
                                    :to="el.to"
                                    v-html="el.name"
                                ></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: "MenuItem",
    props: {
        item: {
            required: true,
        },
    },
    data() {
        return {
            dropdown: null,
        };
    },
    methods: {
        chunks(array) {
            return this.spliceIntoChunks([...array], 4);
        },
        spliceIntoChunks(arr, chunkSize) {
            const res = [];
            while (arr.length > 0) {
                const chunk = arr.splice(0, chunkSize);
                res.push(chunk);
            }
            return res;
        },
    },
    mounted() {
        const { el } = this.$refs;

        if (el) {
            ["show", "shown", "hide", "hidden"].forEach((e) => {
                el.addEventListener(`${e}.bs.dropdown`, () => {
                    // console.log(e, l);
                    this.$emit(e);
                });
            });
        }
    },
};
</script>

<style scoped lang="scss">
.nav-item {
    &.hover {
        &:hover {
            .nav-link {
                background: rgb(241, 245, 249);
            }
        }
    }
}
</style>
