<template>
    <a
        href="#"
        class="d-none"
        data-bs-toggle="modal"
        :ref="'plusLink' + internalId"
        data-bs-target="#modal-plus"
    >
        Plus
    </a>
    <div
        class="modal modal-blur fade"
        id="modal-plus"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-sm modal-dialog-centered"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-title">StreamerPlus</div>
                    <div>
                        <slot></slot>
                    </div>
                </div>
                <div class="modal-footer">
                    <a
                        href="javascript:void(0)"
                        class="me-auto cursor-pointer text-muted"
                        data-bs-dismiss="modal"
                    >
                        Назад
                    </a>
                    <button
                        type="button"
                        class="btn btn-success"
                        data-bs-dismiss="modal"
                        @click="toPlus"
                    >
                        Подробнее
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NeedPlusModal",
    data: () => ({
        internalId: "_id" + Math.random().toString(16).slice(2),
    }),
    methods: {
        toPlus() {
            this.$router.push({ name: "plus" });
        },
        openPlus() {
            this.$refs["plusLink" + this.internalId].click();
        },
    },
};
</script>

<style scoped></style>
