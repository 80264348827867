import { guard } from "@/functions";

export default [
    {
        name: "sound",
        path: "/sound",
        component: () => import("@/modules/Sound"),
        beforeEnter: guard,
    },
];
