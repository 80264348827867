// import api from "@/api";

import axios from "@/axios";

const getters = {
    datasets(state) {
        return state.datasets;
    },
    datasetSounds(state) {
        return state.sounds;
    },
    datasetActions(state) {
        return state.actions;
    },
    datasetActionList(state) {
        return state.actionList;
    },
};

const state = {
    datasets: [
        /*{
            id: 1,
            name: "dataset 1",
            items: [
                {
                    id: 1,
                    name: "test item 1",
                    value: 44,
                },
                {
                    id: 2,
                    name: "test item 2",
                    value: 543,
                },
                {
                    id: 3,
                    name: "test item 3",
                    value: 234,
                },
            ],
        },
        {
            id: 2,
            name: "dataset 2",
            items: [
                {
                    id: 1,
                    name: "test item 1",
                    value: 22,
                },
            ],
        },
        {
            id: 3,
            name: "dataset 3",
            items: [
                {
                    id: 1,
                    name: "test item 1",
                    value: 1,
                },
                {
                    id: 2,
                    name: "test item 2",
                    value: 2,
                },
                {
                    id: 3,
                    name: "test item 3",
                    value: 3,
                },
                {
                    id: 4,
                    name: "test item 4",
                    value: 4,
                },
                {
                    id: 5,
                    name: "test item 5",
                    value: 5,
                },
            ],
        },*/
    ],
    actionList: [],
    actions: [],
    sounds: [],
};

export const SET_DATASETS = "setDatasets";
export const ADD_DATASET = "addDataset";
export const ADD_DATASET_ITEM = "addDatasetItem";
export const REMOVE_DATASET_ITEM = "removeDatasetItem";
export const REMOVE_DATASET_ITEMS = "removeDatasetItems";
export const MODIFY_DATASET_ITEM = "modifyDatasetItem";
export const SET_DATASET_ACTIONS = "setDatasetActions";
export const SET_DATASET_ACTION_LIST = "setDatasetActionList";
export const SET_DATASET_SOUNDS = "setDatasetSounds";

const mutations = {
    [SET_DATASETS](state, payload) {
        state.datasets = payload;
    },
    [ADD_DATASET](state, payload) {
        state.datasets.push(payload);
    },
    [SET_DATASET_SOUNDS](state, payload) {
        state.sounds = payload;
    },
    [ADD_DATASET_ITEM](state, payload) {
        state.datasets
            .find((s) => s.id === payload.dataset_id)
            .items.push(payload.item);
    },
    [REMOVE_DATASET_ITEM](state, payload) {
        state.datasets.find((s) => s.id === payload.dataset_id).items =
            state.datasets
                .find((s) => s.id === payload.dataset_id)
                .items.filter((s) => s.id !== payload.item.id);
    },
    [REMOVE_DATASET_ITEMS](state, payload) {
        state.datasets.find((s) => s.id === payload.dataset_id).items = [];
    },
    [MODIFY_DATASET_ITEM](state, payload) {
        state.datasets.find((s) => s.id === payload.dataset_id).items =
            state.datasets
                .find((s) => s.id === payload.dataset_id)
                .items.map((s) => {
                    if (s.id === payload.item.id) s = payload.item;
                    return s;
                });
    },
    [SET_DATASET_ACTIONS](state) {
        state.actions = [
            {
                id: 1, // new-item
                name: "Действие при добавлении нового элемента",
                text: "При добавлении нового элемента",
                items: state.actionList.filter(
                    (v) => [0, 5].indexOf(v.id) !== -1
                ),
            },
            {
                id: 2, // update-item-value
                name: "Действие при обновлении значения элемента",
                text: "При обновлении значения элемента",
                items: state.actionList.filter(
                    (v) => [0, 5].indexOf(v.id) !== -1
                ),
            },
            {
                id: 3, // delete
                name: "Действие при удалении элемента",
                text: "При удалении элемента",
                items: state.actionList.filter(
                    (v) => [0, 5].indexOf(v.id) !== -1
                ),
            },
            {
                id: 4, // leader-change
                name: "Действие при смене лидера",
                text: "При смене лидера",
                items: state.actionList.filter(
                    (v) => [0, 5].indexOf(v.id) !== -1
                ),
            },
            // {
            //     id: 5, // delete all
            //     name: "Действие при удалении всех элементов",
            //     text: "При удалении всех элементов",
            //     items: state.actionList.filter(
            //         (v) => [0, 5].indexOf(v.id) !== -1
            //     ),
            // },
        ];
    },
    [SET_DATASET_ACTION_LIST](state) {
        state.actionList = [
            {
                id: 0,
                name: "Ничего не делать",
            },
            {
                id: 1,
                name: "Воспроизвести звук",
                items: state.sounds,
            },
            // {
            //     id: 2,
            //     name: "Запустить колесо",
            // },
            // {
            //     id: 3,
            //     name: "Запустить таймер",
            //     items: state.timers,
            // },
            {
                id: 4,
                name: "Перезапустить таймер",
            },
            {
                id: 5,
                name: "Добавить секунды к таймеру",
                inputs: [
                    {
                        placeholder: "секунды",
                    },
                ],
            },
        ];
    },
};

export const GET_DATASETS = "getDatasets";
export const CREATE_DATASET = "createDataset";
export const CREATE_DATASET_ITEM = "createDatasetItem";
export const UPDATE_DATASET_ITEM = "updateDatasetItem";
export const DELETE_DATASET_ITEM = "deleteDatasetItem";
export const DELETE_DATASET_ITEMS = "deleteDatasetItems";
export const DELETE_DATASET = "deleteDataset";
export const UPDATE_DATASET = "updateDataset";
export const ADD_DATASET_ACTION = "addDatasetAction";
export const DELETE_DATASET_ACTION = "deleteDatasetAction";
export const UPDATE_DATASET_ACTION = "updateDatasetAction";
export const GET_DATASET_SOUNDS = "getDatasetSounds";
export const SEARCH_BASE = "searchBase";
export const GET_DATASET_HISTORY = "getDatasetHistory";

const actions = {
    [GET_DATASETS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets")
                .then((response) => {
                    context.commit(SET_DATASETS, response.data);

                    context.commit(SET_DATASET_ACTION_LIST);
                    context.commit(SET_DATASET_ACTIONS);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_DATASET_HISTORY](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.history", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_DATASET_SOUNDS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.sounds")
                .then((response) => {
                    context.commit(SET_DATASET_SOUNDS, response.data);

                    context.commit(SET_DATASET_ACTION_LIST);
                    context.commit(SET_DATASET_ACTIONS);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [CREATE_DATASET](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.create", payload)
                .then((response) => {
                    context.commit(ADD_DATASET, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [CREATE_DATASET_ITEM](context, payload) {
        return new Promise((resolve, reject) => {
            const randomBetween = (min, max) =>
                min + Math.floor(Math.random() * (max - min + 1));
            const r_bg = randomBetween(0, 255);
            const g_bg = randomBetween(0, 255);
            const b_bg = randomBetween(0, 255);
            const bg = `rgb(${r_bg},${g_bg},${b_bg})`; // Collect all to a css color string

            let rgb = bg.split(/\(([^)]+)\)/)[1].replace(/ /g, "");

            // map RGB values to variables
            let r = parseInt(rgb.split(",")[0], 10),
                g = parseInt(rgb.split(",")[1], 10),
                b = parseInt(rgb.split(",")[2], 10);

            // if RGBA, map alpha to variable (not currently in use)
            // if (rgb.split(",")[3] !== null) {
            //     a = parseInt(rgb.split(",")[3], 10);
            //     console.log(a);
            // }

            // calculate contrast of color (standard grayscale algorithmic formula)
            let contrast =
                (Math.round(r * 299) +
                    Math.round(g * 587) +
                    Math.round(b * 114)) /
                1000;

            payload.item.color =
                contrast >= 128 ? "rgb(0,0,0)" : "rgb(255,255,255)";
            payload.item.background = bg;

            axios
                .post("/datasets.createItem", payload)
                .then((response) => {
                    context.commit(ADD_DATASET_ITEM, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_DATASET_ITEM](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.updateItem", payload)
                .then((response) => {
                    context.commit(MODIFY_DATASET_ITEM, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [DELETE_DATASET_ITEM](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.deleteItem", payload)
                .then((response) => {
                    context.commit(REMOVE_DATASET_ITEM, payload);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [DELETE_DATASET_ITEMS](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.deleteItems", payload)
                .then((response) => {
                    context.commit(REMOVE_DATASET_ITEMS, payload);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [DELETE_DATASET](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.delete", payload)
                .then((response) => {
                    context.dispatch(GET_DATASETS);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_DATASET](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.update", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [ADD_DATASET_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.action.add", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [DELETE_DATASET_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.action.delete", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_DATASET_ACTION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/datasets.action.update", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [SEARCH_BASE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/datasets.search",
                    {
                        name: payload.name,
                        bases: payload.bases,
                    },
                    {
                        cancelToken: payload.cancelToken,
                    }
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
