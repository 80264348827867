// import api from "@/api";

const state = {
    collections: [],
};
const mutations = {};
const actions = {};

export default {
    state,
    mutations,
    actions,
};
