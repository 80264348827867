// import api from "@/api";

import axios from "@/axios";

const getters = {
    fonts(state) {
        return state.fonts;
    },
};

const state = {
    fonts: [],
};

export const SET_FONTS = "setFonts";

const mutations = {
    [SET_FONTS](state, payload) {
        state.fonts = payload;
    },
};

export const GET_FONTS = "getFonts";
const actions = {
    [GET_FONTS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/fonts")
                .then((response) => {
                    context.commit(SET_FONTS, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
