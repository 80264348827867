export default [
    {
        name: "terms",
        path: "/terms",
        component: () => import("@/views/Rules/TermsView"),
    },
    {
        name: "personal",
        path: "/personal",
        component: () => import("@/views/Rules/PersonalView"),
    },
];
