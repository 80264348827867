<template>
    <HeaderLayout>
        <slot name="header"></slot>
    </HeaderLayout>
    <!-- Page body -->
    <div class="page-body">
        <div class="container-xl">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import HeaderLayout from "@/layouts/Base/Header";
export default {
    name: "ContainerLayout",
    components: { HeaderLayout },
};
</script>

<style scoped></style>
