import { guard } from "@/functions";

export default [
    {
        name: "chest",
        path: "/chest",
        component: () => import("@/modules/Chest"),
        beforeEnter: guard,
    },
];
