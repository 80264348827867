import { createStore } from "vuex";
import wheel from "@/store/modules/wheel";
import collection from "@/store/modules/collection";
import user from "@/store/modules/user";
import dataset from "@/store/modules/dataset";
import timer from "@/store/modules/timer";
import sound from "@/store/modules/sound";
import font from "@/store/modules/font";
import battleroyal from "@/store/modules/battleroyal";
import settings from "@/store/modules/settings";
import prediction from "@/store/modules/prediction";
import donate from "@/store/modules/donate";

export default createStore({
    modules: {
        user,
        collection,
        wheel,
        dataset,
        timer,
        sound,
        font,
        battleroyal,
        settings,
        prediction,
        donate,
    },
});
