import { guard } from "@/functions";

export default [
    {
        name: "top",
        path: "/top",
        component: () => import("@/modules/Top"),
        beforeEnter: guard,
    },
];
