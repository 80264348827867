// import api from "@/api";

import axios from "@/axios";

const getters = {
    battleRoyalItems(state) {
        return state.items;
    },
    battleRoyalStyles(state) {
        return state.styles;
    },
};

const state = {
    items: [],
    styles: [],
};

export const SET_BR_ITEMS = "setBrItems";
export const SET_BR_STYLES = "setBrStyles";
export const ADD_BR_STYLE = "addBrStyle";
export const MODIFY_BR_STYLE = "modifyBrStyle";

const mutations = {
    [SET_BR_ITEMS](state, payload) {
        state.items = payload;
    },
    [SET_BR_STYLES](state, payload) {
        state.styles = payload;
    },
    [ADD_BR_STYLE](state, payload) {
        state.styles.push(payload);
    },
    [MODIFY_BR_STYLE](state, payload) {
        state.styles = state.styles.map((s) => {
            if (s.id === payload.id) s = payload;
            return s;
        });
    },
};

export const SPIN_BR = "spinBr";
export const GET_BR_ITEMS = "getBrItems";
export const SAVE_BR_WINNER = "saveBrWinner";
export const CREATE_BR_STYLE = "createBrStyle";
export const GET_BR_STYLES = "getBrStyles";
export const UPDATE_BR_STYLE = "updateBrStyle";

const actions = {
    [SPIN_BR]() {
        return new Promise((resolve, reject) => {
            axios
                .post("/royal.spin")
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_BR_ITEMS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/royal.items")
                .then((response) => {
                    context.commit(SET_BR_ITEMS, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [SAVE_BR_WINNER](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/royal.save", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_BR_STYLES](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/royal.styles")
                .then((response) => {
                    context.commit(SET_BR_STYLES, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [CREATE_BR_STYLE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/royal.styles.create", payload)
                .then((response) => {
                    context.commit(ADD_BR_STYLE, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_BR_STYLE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/royal.styles.update", payload)
                .then((response) => {
                    context.commit(MODIFY_BR_STYLE, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
