<template>
    <!--    <div
        class="d-flex flex-column text-center mt-5 justify-content-center align-self-center"
    >
        <div class="display-1">404</div>
        <a href="javascript:void(0)" @click="$router.back()" class="mt-2"
            >Назад</a
        >
    </div>-->
    <div class="vh-100">
        <div class="page page-center">
            <div class="container-tight py-4">
                <div class="empty">
                    <div class="empty-header">404</div>
                    <p class="empty-title">
                        Упс… Вы попали на несуществующую страницу
                    </p>
                    <p class="empty-subtitle text-muted">
                        Нам жаль, но тут ничего нет.
                    </p>
                    <div class="empty-action">
                        <a
                            href="javascript:void(0)"
                            @click="$router.back()"
                            class="btn btn-primary"
                        >
                            <!-- Download SVG icon from http://tabler-icons.io/i/arrow-left -->
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M5 12l6 6"></path>
                                <path d="M5 12l6 -6"></path>
                            </svg>
                            Назад
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
    name: "NotFound",
    mounted() {},
    beforeCreate() {
        useHead({
            title: "404",
            meta: [
                {
                    name: "description",
                    content: "Страница не найдена.",
                },
            ],
        });
    },
};
</script>

<style scoped></style>
