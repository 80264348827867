import axios from "@/axios";

const getters = {
    donates(state) {
        return state.donates;
    },
};

const state = {
    donates: [],
};

export const SET_DONATES = "setDonates";
export const ADD_DONATE = "addDonate";

const mutations = {
    [SET_DONATES](state, payload) {
        state.donates = payload;
    },
    [ADD_DONATE](state, payload) {
        state.donates.unshift(payload);
    },
};

export const GET_LAST_DONATES = "getLastDonates";
export const GET_WAIT_DONATES = "getWaitDonates";
export const RESOLUTION_DONATION = "resolutionDonation";

const actions = {
    [GET_LAST_DONATES](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/donate.last")
                .then((response) => {
                    context.commit(SET_DONATES, response.data);
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_WAIT_DONATES](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/donate.wait", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [RESOLUTION_DONATION](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/donate.resolution", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
