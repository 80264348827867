import { guard } from "@/functions";

export default [
    {
        name: "lobby",
        path: "/lobby",
        component: () => import("@/modules/Lobby"),
        beforeEnter: guard,
    },
];
