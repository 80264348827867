import { guard } from "@/functions";

export default [
    {
        name: "chatdraw",
        path: "/chatdraw",
        component: () => import("@/modules/ChatDraw"),
        beforeEnter: guard,
    },
];
